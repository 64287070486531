
.container {
	width: 70%;
}

h1, h2, h3 {
	padding: 0;
	margin: 1.3em 0 0.5em 0;
}

h3 {
	text-align: center;
}

div.users div{
	padding: 1em;
}

.navbar-brand {
	font-family: $headings-font-family;
}

.chapter-content {
	padding: 1.5em;
}

.card {
	margin-bottom: 1em;
}

/* Sticky footer styles
Srce: https://getbootstrap.com/docs/4.0/examples/sticky-footer/
-------------------------------------------------- */
html {
	position: relative;
	min-height: 100%;
}
body {
	margin-bottom: 60px; /* Margin bottom by footer height */
}
.footer {
	color: $navbar-dark-brand-color;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 60px; /* Set the fixed height of the footer here */
	line-height: 60px; /* Vertically center the text there */
}
.footer p {
	margin: 0;
	padding: 0 0.8em;
}
